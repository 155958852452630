import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue')
  },
  {
    path: '/Apresentacao',
    name: 'Apresentacao',
    component: () => import('../components/Apresentacao.vue')
  },
  {
    path: '/recuperar/senha',
    name: 'RecuperarSenha',
    component: () => import('../components/RecuperarSenha.vue')
  },
  {
    path: '/redefinir/senha/:token',
    name: 'RedefinirSenha',
    component: () => import('../components/RedefinirSenha.vue')
  },
  {
    path: '/realizar/etapa/:token',
    name: 'Realizar Etapa',
    component: () => import('../components/RealizarEtapa.vue')
  },
  {
    path: '/home',
    name: 'Inicio',
    component: Home
  },
  {
    path: '/LogEmails',
    name: 'LogEmails',
    component: () => import('../views/LogEmails.vue')
  },
  {
    path: '/portal/:idcliente/:hashcliente',
    name: 'Portal',
    component: () => import('../views/Portal.vue')
  },
  {
    path: '/',
    name: 'DashBoard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/AgendaImplantacao',
    name: 'AgendaImplantacao',
    component: () => import('../views/AgendaImplantacao.vue')
  },
  {
    path: '/QuadroImplantacao',
    name: 'QuadroImplantacao',
    component: () => import('../views/QuadroImplantacao.vue')
  },
  {
    path: '/ServidoresSuporte',
    name: 'ServidoresSuporte',
    component: () => import('../views/ServidoresSuporte.vue')
  },
  {
    path: '/Treinamentos',
    name: 'Treinamentos',
    component: () => import('../views/Treinamentos.vue')
  },
  {
    path: '/Aprendizado',
    name: 'Aprendizado',
    component: () => import('../views/Aprendizado.vue')
  },
  {
    path: '/CriarArtigo',
    name: 'CriarArtigo',
    component: () => import('../views/CriarArtigo.vue')
  },
  {
    path: '/ListaDeArtigos/:idcategoria',
    name: 'ListaDeArtigos',
    component: () => import('../views/ListaDeArtigos.vue')
  },
  {
    path: '/ListaDeArtigosPesquisado',
    name: 'ListaDeArtigosPesquisado',
    component: () => import('../views/ListaDeArtigosPesquisado.vue')
  },
  {
    path: '/Artigo/:idartigo',
    name: 'Artigo',
    component: () => import('../views/Artigo.vue')
  },
  {
    path: '/PreRequisitos',
    name: 'PreRequisitos',
    component: () => import('../views/PreRequisitos.vue')
  },
  {
    path: '/Projetos',
    name: 'Projetos',
    component: () => import('../views/Projetos.vue')
  },
  {
    path: '/Solicitacoes',
    name: 'Solicitacoes',
    component: () => import('../views/Solicitacoes.vue')
  },
  {
    path: '/Atividades',
    name: 'Atividades',
    component: () => import('../views/Atividades.vue')
  },
  {
    path: '/Contatos',
    name: 'Contatos',
    component: () => import('../views/Contatos.vue')
  },
  {
    path: '/Robos',
    name: 'Robos',
    component: () => import('../views/Robos.vue')
  },
  {
    path: '/Drives',
    name: 'Drives',
    component: () => import('../views/Drives.vue')
  },
  {
    path: '/Parceiros',
    name: 'Parceiros',
    component: () => import('../views/Parceiros.vue')
  },
  {
    path: '/Layouts',
    name: 'Layouts',
    component: () => import('../views/Layouts.vue')
  },
  {
    path: '/PrioridadesPlanejadas',
    name: 'PrioridadesPlanejadas',
    component: () => import('../views/PrioridadesPlanejadas.vue')
  },
  {
    path: '/Jenkins',
    name: 'Jenkins',
    component: () => import('../views/Jenkins.vue')
  },
  {
    path: '/Termos',
    name: 'Termos',
    component: () => import('../views/Termos.vue')
  },
  {
    path: '/StatusSistemas',
    name: 'StatusSistemas',
    component: () => import('../views/StatusSistemas.vue')
  },
  // {
  //   path: '/videosutoriais',
  //   name: 'VideoTutoriais',
  //   component: () => import('../views/VideosTutoriais.vue')
  // },
  {
    path: '/Projetos/Atividades/:idprojeto',
    name: 'ProjetosAtividades',
    component: () => import('../views/Atividades.vue')
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/Prioridades',
    name: 'Prioridades',
    component: () => import('../views/Prioridades.vue')
  },
  {
    path: '/loginvotacao',
    name: 'loginVotacao',
    component: () => import('../views/loginvotacao.vue')
  },
  {
    path: '/votacao',
    name: 'votacao',
    component: () => import('../views/votacao.vue')
  },
  {
    path: '/utilizacaowhatsapp/:idcliente',
    name: 'whatsapp',
    component: () => import('../views/whatsapp.vue')
  },
  {
    path: '/Prioridades',
    name: 'Prioridades',
    component: () => import('../views/Prioridades.vue')
  },
  {
    path: '/Relatorios',
    name: 'Relatorios',
    component: () => import('../views/Relatorios.vue')
  },
  {
    path: '/criar/implantacao',
    name: 'CriarImplantacao',
    component: () => import('../views/CriarImplantacao.vue')
  },
  {
    path: '/lista/implantacao/:id',
    name: 'AtualizarImplantacao',
    component: () => import('../views/AtualizarImplantacao.vue')
  },
  {
    path: '/lista/implantacao/:id/checklist',
    name: 'AtualizarImplantacao',
    component: () => import('../views/CheckiListResponder.vue')
  },
  {
    path: '/lista/implantacao/realizar/:id/:idcliente',
    name: 'RealizarEtapa',
    component: () => import('../components/Etapa/RealizarEtapa.vue')
  },
  {
    path: '/grafico/implantacao/:id',
    name: 'ImplantacaoGrafico',
    component: () => import('../views/ImplantacaoGrafico.vue')
  },
  {
    path: '/lista/usuario',
    name: 'ListaUsuario',
    component: () => import('../views/ListaUsuario.vue')
  },
  {
    path: '/lista/usuario/:id',
    name: 'AtualizarUsuario',
    component: () => import('../views/AtualizarUsuario.vue')
  },
  {
    path: '/criar/usuario',
    name: 'CriarUsuario',
    component: () => import('../views/CriarUsuario.vue')
  },
  {
    path: '/lista/stakeholder',
    name: 'ListaStakeholder',
    component: () => import('../views/ListaStakeholder.vue')
  },
  {
    path: '/lista/stakeholder/:id',
    name: 'AtualizarStakeholder',
    component: () => import('../views/AtualizarStakeholder.vue')
  },
  {
    path: '/criar/stakeholder',
    name: 'CriarStakeholder',
    component: () => import('../views/CriarStakeholder.vue')
  },
  {
    path: '/criar/gadget',
    name: 'CriarGadget',
    component: () => import('../views/CriarGadget.vue')
  },
  {
    path: '/lista/gadget',
    name: 'ListaGadget',
    component: () => import('../views/ListaGadget.vue')
  },
  {
    path: '/lista/gadget/:id',
    name: 'AtualizarGadget',
    component: () => import('../views/AtualizarGadget.vue')
  },
  {
    path: '/criar/etapa',
    name: 'CriarEtapa',
    component: () => import('../views/CriarEtapa.vue')
  },
  {
    path: '/lista/etapa',
    name: 'ListaEtapa',
    component: () => import('../views/ListaEtapa.vue')
  },
  {
    path: '/lista/etapa/:id',
    name: 'AtualizarEtapa',
    component: () => import('../views/AtualizarEtapa.vue')
  },
  {
    path: '/criar/fluxo',
    name: 'CriarFluxo',
    component: () => import('../views/CriarFluxo.vue')
  },
  {
    path: '/lista/fluxo',
    name: 'ListaFluxo',
    component: () => import('../views/ListaFluxo.vue')
  },
  {
    path: '/lista/fluxo/:id',
    name: 'AtualizarFluxo',
    component: () => import('../views/AtualizarFluxo.vue')
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('../views/Agenda.vue')
  },
  {
    path: '/teste',
    name: 'Teste',
    component: () => import('../views/Teste.vue')
  },
  {
    path: '/criar/cliente',
    name: 'CriarCliente',
    component: () => import('../views/CriarCliente.vue')
  },
  {
    path: '/lista/cliente',
    name: 'ListaCliente',
    component: () => import('../views/ListaCliente.vue')
  },
  {
    path: '/lista/dadossistemas',
    name: 'DadosSistemas',
    component: () => import('../views/DadosSistemas.vue')
  },
  {
    path: '/ContatoParceiros',
    name: 'ContatoParceiros',
    component: () => import('../views/ContatoParceiros.vue')
  },
  {
    path: '/CredenciaisParceiros',
    name: 'CredenciaisParceiros',
    component: () => import('../views/CredenciaisParceiros.vue')
  },
  {
    path: '/lista/cliente/:id',
    name: 'AtualizarCliente',
    component: () => import('../views/AtualizarCliente.vue')
  },
  {
    path: '/criar/faq',
    name: 'CriarFaq',
    component: () => import('../views/CriarFaq.vue')
  },
  {
    path: '/ver/faq',
    name: 'VerFaq',
    component: () => import('../views/VerFaq.vue')
  },
  {
    path: '/lista/faq',
    name: 'ListaFaq',
    component: () => import('../views/ListaFaq.vue')
  },
  {
    path: '/lista/faq/:id',
    name: 'AtualizarFaq',
    component: () => import('../views/AtualizarFaq.vue')
  },
  {
    path: '/lista/modulos',
    name: 'ListaModulosClientes',
    component: () => import('../views/ListaModulosClientes.vue')
  },  
  {
    path: '/lista/utilizacao',
    name: 'ListaUtlização',
    component: () => import('../views/ListaUtilizacaoClientes.vue')
  },  
  {
    path: '/tutoriais',
    name: 'Tutoriais',
    component: () => import('../components/old/Tutoriais.vue')
  },
  {
    path: '/apis/status',
    name: 'StatusAPIs',
    component: () => import('../views/StatusAPIs.vue')
  },
  {
    path: '/sql/monitor',
    name: 'SQLMonitor',
    component: () => import('../views/SQLMonitor.vue')
  },
  // {
  //   path: '/perguntas',
  //   name: 'Perguntas',
  //   component: () => import('../components/old/Perguntas.vue')
  // },
  // {
  //   path: '/tutorial/:id',
  //   name: 'Tutorial',
  //   component: () => import('../components/old/Tutorial.vue')
  // },
  // {
  //   path: '/usuarios',
  //   name: 'Usuarios',
  //   component: () => import('../components/old/Usuarios.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/About.vue')
  // },



  {
    path: '/lista/sistema',
    name: 'ListaSistema',
    component: () =>
        import ('../views/ListaSistema.vue')
},
{
    path: '/criar/sistema',
    name: 'CriarSistema',
    component: () =>
        import ('../views/CriarSistema.vue')
},
{
    path: '/lista/sistema/:id',
    name: 'AtualizarSistema',
    component: () =>
        import ('../views/AtualizarSistema.vue')
},
{
  path: '/relatorios/curtidasfaqvideos',
  name: 'RelatorioFaqVideo',
  component: () => import('../views/ListarRelatorioFaq.vue')
},
{
  path: '/formulario/:id/:idcontrato',
  name: 'Formulário',
  component: () => import('../components/Formulário/Formulario.vue')
},

{
  path: '/enviaratualizacoes',
  name: 'EnviarUltimasAtualizacoes',
  component: () => import('../views/EnviarUltimasAtualizacoes.vue')
},

{
  path: '/cadastrar/contrato',
  name: 'CadastrarContrato',
  component: () => import('../components/Contrato/CadastrarContrato.vue')
},

{
  path: '/cadastrar/stackholdersTipo',
  name: 'CriarStakeHolderTipos',
  component: () => import('../components/Stakeholder/CriarStakeHolderTipos.vue')
},
{
  path: '/listar/contratos',
  name: 'ListarContratos',
  component: () => import('../components/Contrato/ListarContratos.vue')
},
{
  path: '/editar/contrato',
  name: 'EditarContrato',
  component: () => import('../components/Contrato/EditarContrato.vue')
},
{
  path: '/exibir/contratos/cliente',
  name: 'ListarContratosCliente',
  component: () => import('../components/Cliente/ListarContratosCliente.vue')
},
{
  path: '/aplicativos/politicadeprivacidade',
  name: 'PoliticaDePrivacidade',
  component: () => import('../components/PoliticaDePrivacidade.vue')
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router