<template>
  <v-container>
    <div class="header" style="">
      <span>
        <h3>Implantações (BETA) V2</h3>
      </span>
    </div>
    <div style="border-radius:5px;margin-bottom:5px;display:flex;flex-direction:column;">
         <v-container class="">
            <v-row>

              <v-col >
                <v-select v-model="sistema" label="Sistema" item-text="nome" item-value="id" :items="listadesistema" outlined return-object hide-details dense >
                </v-select>
              </v-col>

              <v-col>
                <!-- <v-select color v-model="cliente" label="Cliente" item-text="nome" item-value="id" :items="clientes" outlined return-object hide-details dense>
                </v-select> -->
                <v-text-field type="" label="Digite um nome" style="margin-bottom:0px;padding-bottom:0px!important;" v-model="nomecliente" outlined hide-details dense></v-text-field>
              </v-col>

              <v-col  >
                <v-select style="" color v-model="statuscliente" label="Status Cliente" item-text="nome" item-value="id" :items="listadestatuscliente" outlined return-object hide-details dense>
                </v-select>
              </v-col>

              <v-col  >
                <v-select style="" color v-model="status" label="Status" item-text="nome" item-value="id" :items="listadestatus" outlined return-object hide-details dense>
                </v-select>
              </v-col>

              <v-col>
                <v-select v-model="statuschecklist" label="CheckList" item-text="nome" item-value="id" :items="listastatuschecklist" outlined return-object hide-details dense>
                </v-select>
              </v-col>
              

            </v-row>

            <v-row style="margin-top:-10px;"> 
              
              <v-col>
                    
                  
              </v-col>

              <v-col>
                
              </v-col>

              <v-col style="">
                  <v-row>
                    <v-col>
                        <v-switch label="Implantações Ativadas" v-model="fl_ativo" style="margin-top:0px;"></v-switch>
                    </v-col>
                    <v-col>
                        <v-btn small elevation="" color="error" outlined @click="() =>limparFiltros()" block> Limpar </v-btn>                         
                    </v-col>
                  </v-row>
              </v-col>

              <v-col>
                  <v-row>
                    <!-- <v-col>
                        <v-btn small elevation="" color="info" @click="() => {criar()}" block> Criar </v-btn>                         
                    </v-col>-->
                    <v-col> 
                        <v-btn small elevation="" @click="() => requisitarImplantacoes()" color="success" block>  Pesquisar</v-btn>       
                    </v-col>
                  </v-row>
                  
              </v-col>
              

            </v-row>

          </v-container>
        
        
    </div>

     <v-container class="">
        <v-row>
          <v-col>

            <v-data-table :items="implantacoes" :headers="headerImplantacoes" :items-per-page="20" >

               <template v-slot:[`item.concluida`]="{ item }">
                    <p :style="'color:'+(item.cor)">
                        {{item.descricao_status}}
                    </p>
                    <!-- <p :style="(item.concluida ?'color:green':(item.atrasado == 'atrasado' ?'color:red':'color:gray'))">
                        {{item.concluida ? 'Concluido' :  (item.atrasado == 'atrasado' ?'Atrasado':(item.qtde_etapas_pendentes > 0 ?'Pendentes':'-'))}}
                    </p> -->
                </template>

                <template v-slot:[`item.impedido`]="{ item }">
                    <p v-if="item.impedido > 0" style="color:red;font-weight:bold;" >
                        Impedido
                    </p>
                    <p v-else style="" >
                    </p>
                </template>

                <template v-slot:[`item.percentual_checklist`]="{ item }">
                    
                     
                              <div style="display:flex;flex-direction:row;width:300px;justify-content:space-between;">
                                  <div>
                                      <v-btn small @click="setModalAgenda({idimplantacao:item.id,impedido:item.impedido,motivo_impedido:item.motivo_impedido,idcliente:item.idcliente,idsistema:item.idsistema,nomecliente:item.nomecliente,nomesistema:item.nomesistema})" :color="(item.qtde_etapas_pendentes > 0 ?'error':'success')" :outlined="(item.percentualchecklist< 100 ?false:true)" style="width:50px;">
                                        <v-icon color="">mdi-notebook-check-outline</v-icon>
                                      </v-btn>
                                  </div>

                                  <div>
                                      <v-btn small @click="responderChecklist(item)" :color="(item.percentual_checklist< 100 ?'error':'success')" 
                                        :outlined="(item.percentual_checklist< 100 ?false:true)" style="width:50px;">
                                        {{item.percentual_checklist}}%
                                      </v-btn>
                                  </div>

                                  <div>

                                      <v-btn small @click="setModalMovimentos(item.id)" color="info" style="width:40px;" :outlined="item.qtdeobservacoes>0?false:true" >
                                          {{item.qtdeobservacoes}}
                                      </v-btn>

                                  </div>

                                  <!-- <div>
                                      <v-btn v-if="!item.concluida" elevation="0" :ripple="false" class="btn-acao" data-cy="btn-acao" color="#1377F2" type="button" @click="iniciarImplantacao(item.id)" style="color:white;height:30px;width:80px;" >
                                        {{item.datainicio? 'Editar': 'Iniciar' }}
                                      </v-btn>
                                      <v-btn v-if="item.concluida" elevation="0" :ripple="false" class="btn-acao" data-cy="btn-acao" color="#1377F2" outlined type="button" @click="redirecionar(`lista/implantacao/${item.id}`)" style="height:30px;width:80px;" >
                                        ver
                                      </v-btn>

                                  </div> -->

                                  <!-- <div>
                                      <v-btn v-if="item.fl_ativo" small @click="setModalCancelamento(item.id)" color="error" style="width:30 px;" title="Desativar" outlined>
                                          <v-icon color="">mdi-delete</v-icon>
                                      </v-btn>
                                      <v-btn v-else small @click="setModalAtivar(item.id)" color="success" style="width:30 px;" title="Ativar" outlined>
                                          <v-icon color="">mdi-check</v-icon>
                                      </v-btn>
                                  </div> -->
                            </div>

                         
                </template>
              
              
            </v-data-table>

          </v-col>
        </v-row>
      </v-container>

  

    <v-dialog v-model="exibirModalMovimentos" width="80vw" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>
                      <v-col cols="12" md="2">
                            <v-select v-model="tipoobs" label="Tipo" item-text="nome" item-value="id" :items="listatipoobs" outlined return-object hide-details dense>
                            </v-select>
                      </v-col>

                      <v-col cols="12" md="8">
                            <v-text-field type="text" label="Evento"  v-model="observacao" outlined return-object hide-details dense>
                            </v-text-field>
                            
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-btn elevation="" @click="() => registrarObservacao()" color="info" block>  Registrar</v-btn> 
                      </v-col>
                    </v-row>
                  </v-container>
              </div>
              <v-data-table :headers="headersmovimentacao" :items="listamovimentacao" class="elevation-1" >
                    <template v-slot:[`item.dia`]="{ item }">
                        {{item.dia | formatarDataHora}}
                    </template>

                    <template v-slot:[`item.tipo`]="{ item }">
                        <span :style="item.negativo?'color:red;font-weight:bold;':''">{{ item.tipo }}</span>
                    </template>

              </v-data-table>
          </div>
    </v-dialog>


    <v-dialog v-model="exibirModalCancelamento" width="50vw" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>
                     

                      <v-col cols="12" md="10">
                            <v-text-field type="text" label="Motivo da Desativação"  v-model="motivoDesativacao" outlined hide-details dense>
                            </v-text-field>
                            
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-btn elevation="" @click="() => desativar()" color="info" block>  Confirmar</v-btn> 
                      </v-col>
                    </v-row>
                  </v-container>
              </div>
             
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalAtivar" width="50vw" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>
                     

                      <v-col cols="12" md="10">
                            <v-text-field type="text" label="Motivo da Ativação"  v-model="motivoAtivacao" outlined hide-details dense>
                            </v-text-field>
                            
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-btn elevation="" @click="() => ativar()" color="info" block>  Confirmar</v-btn> 
                      </v-col>
                    </v-row>
                  </v-container>
              </div>
             
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalAgenda" width="80vw" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
             <!-- {{implantacaoAgendaSelecionada}} -->
              <div v-if="implantacaoAgendaSelecionada">
                 <v-container class="pa-8">
                    
                    <!-- {{implantacaoAgendaSelecionada}} -->
                    <v-row style="margin-top:5px;">
                        <v-col cols="12" md="9" >
                              <h1>Agenda Implantação 1 - {{implantacaoAgendaSelecionada.nomesistema}}</h1>
                              <!-- {{implantacaoAgendaSelecionada}} -->
                        </v-col>
                        <v-col cols="12" md="3" style="padding-right:10px;" >
                                  <v-btn large elevation="" color="success" block @click="setModalEtapasPadrao()" >Carregar Padrão</v-btn>
                        </v-col>
                    </v-row>
                      
                    <v-row style="margin-top:5px;">
                      <v-col cols="12" md="4" >
                            <h3 style="">Cliente: {{implantacaoAgendaSelecionada.nomecliente}}</h3>
                      </v-col>
                      <v-col cols="12" md="8" >
                        <!-- <v-btn v-if="implantacaoAgendaSelecionada.impedido > 0" small @click="() => setModalImpedimento()" color="error" >
                            <v-icon color="">mdi-flag</v-icon>
                        </v-btn> -->

                        
                        
                        <!-- <v-icon color="">mdi-delete</v-icon> -->
                        <!-- <v-btn v-if="implantacaoAgendaSelecionada.impedido > 0" elevation="" @click="() => setModalImpedimento()" color="error" block>Remover Impedimento</v-btn> 
                        <v-btn v-else elevation="" @click="() => setModalImpedimento()" color="error" block>Adicionar Impedimento</v-btn>  -->
                      </v-col>
                    </v-row>
                    <v-row style="margin-top:20px;">
                     

                      <v-col cols="12" md="6" style="display:none;">
                            <v-text-field type="text" label="Etapa"  v-model="etapa_agendaimplantacao_sel.id" outlined hide-details dense>
                            </v-text-field> 
                      </v-col>
                      <v-col cols="12" md="3">
                            <v-text-field type="text" label="Etapa"  v-model="etapa_agendaimplantacao_sel.texto" outlined hide-details dense>
                            </v-text-field> 
                      </v-col>
                      <v-col cols="12" md="2">
                            <v-text-field type="date" label="Data"  v-model="etapa_agendaimplantacao_sel.data_entrega" outlined hide-details dense>
                            </v-text-field> 
                      </v-col>
                      
                      <v-col cols="12" md="2">
                            <v-text-field type="time" label="Hora"  v-model="etapa_agendaimplantacao_sel.hora_entrega" outlined hide-details dense>
                            </v-text-field> 
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-btn elevation="" @click="() => limparSalvarAgendamento()" color="warning" block>Limpar</v-btn> 
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn elevation="" @click="() => salvarAgendamento()" color="info" block>Salvar</v-btn> 
                      </v-col>

                    </v-row>

                    <!-- <v-row style="margin-top:5px;padding-right:100px;">
                     

                      <v-col cols="12" md="10">
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn v-if="implantacaoAgendaSelecionada.impedido > 0" elevation="" @click="() => setModalImpedimento()" color="error" block>Remover Impedimento</v-btn> 
                        <v-btn v-else elevation="" @click="() => setModalImpedimento()" color="error" block>Adicionar Impedimento</v-btn> 
                      </v-col>
                    </v-row> -->
                    <v-row>

                        <v-data-table v-if="items_agenda && items_agenda.length > 0" 
                            :headers="headers_agenda"
                            :items="items_agenda"
                            style="width:100%"
                            sort-by="data_entrega"
                        >
                            <template v-slot:[`item.id`]="{ item }">
                                <a :style="`color:${item.cor}; ${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.id}}</a>
                            </template>

                            <template v-slot:[`item.qtde_subs`]="{ item }">
                                <a :style="`color:${item.cor};${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.qtde_subs}}</a>
                            </template>
                            
                            <template v-slot:[`item.impedido`]="{ item }">
                                    <v-btn v-if="item.impedido"  small elevation="" color="" @click="removerImpedidoTarefa(item)">
                                        <v-icon color="error">mdi-flag</v-icon>
                                    </v-btn>
                                    <v-btn v-else  small elevation="" color="" @click="adicionarImpedidoTarefa(item)">
                                        <v-icon color="#b7c8cd">mdi-flag</v-icon>
                                    </v-btn>
                                
                            </template>

                            <template v-slot:[`item.texto`]="{ item }">
                                <a :style="`color:${item.cor};${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.texto}}</a>
                            </template>

                            <template v-slot:[`item.data_entrega_formatado`]="{ item }">
                                <a :style="`color:${item.cor};${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.data_entrega_formatado }}</a>
                            </template>

                            <template v-slot:[`item.hora_entrega_formatado`]="{ item }">
                                <a :style="`color:${item.cor};${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.hora_entrega_formatado}}</a>
                            </template>

                            <template v-slot:[`item.idaux`]="{ item }">
                                <div v-if="item.qtde_subs > 0" style="display:flex;column:row;">
                                    <v-btn style="margin-left:10px" v-if="!item.fl_feito" small elevation="" color="info" @click="setarModalSubTarefas(item)">A Fazer</v-btn>
                                    <v-btn style="margin-left:10px" v-else small elevation="" color="error" @click="setarModalSubTarefas(item)">Desfazer</v-btn>
                                </div>
                                <div v-else style="display:flex;column:row;">
                                    <v-btn style="margin-left:10px" v-if="item.criacao_manual" small elevation="" color="error" @click="setarModalConfirmarDelete(item)">
                                      <v-icon color="">mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn style="margin-left:10px" v-if="!item.fl_feito" small elevation="" color="info" @click="setarModalFeito(item)">A Fazer</v-btn>
                                    <v-btn style="margin-left:10px" v-else small elevation="" color="error" @click="setarModalDesfazer(item)">Desfazer</v-btn>
                                </div>
                            </template>
                            <!-- <template v-slot:[`item.idauxremover`]="{ item }">
                                    
                            </template> -->

                        </v-data-table>
                    
                    </v-row>
                  </v-container>
              </div>
             
          </div>
    </v-dialog>


    <v-dialog v-model="exibirModalSubTask" width="70vw" max-width="70vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
            <!-- {{etapaMarcarFeitoSelecionado}} -->
              <div v-if="implantacaoAgendaSelecionada">
                 <v-container class="pa-8">
                    
                    
                    <v-row style="margin-top:5px;">
                        <v-col cols="12" md="10" >
                              <h1>Subtarefas</h1>
                              <h2>{{etapaMarcarFeitoSelecionado && etapaMarcarFeitoSelecionado.texto}}</h2>
                        </v-col>
                    </v-row>
                      
                    <v-row style="margin-top:5px;">
                      <v-col cols="12" md="6" >
                            <h3 style="color:blue">{{implantacaoAgendaSelecionada.nomecliente}}</h3>
                      </v-col>
                      <v-col cols="12" md="6" >
                      </v-col>
                    </v-row>

                    


                    <v-row>

                        <v-data-table v-if="items_subtask && items_subtask.length > 0" 
                            :headers="headers_subtask"
                            :items="items_subtask"
                            style="width:100%"
                        >
                            <template v-slot:[`item.id`]="{ item }">
                                <a :style="`color:${item.cor}; ${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.id}}</a>
                            </template>

                            <template v-slot:[`item.etapa`]="{ item }">
                                <a :style="`color:${item.cor};${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.etapa}}</a>
                            </template>

                            <template v-slot:[`item.dia`]="{ item }">
                                <a :style="`color:${item.cor};${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.dia | formatarData}}</a>
                            </template>

                            <template v-slot:[`item.hora`]="{ item }">
                                <a :style="`color:${item.cor};${item.fl_feito?'text-decoration: line-through;':''}`" @click="selecionarEtapaDoAgendamento(item)">{{item.hora | formatarHora}}</a>
                            </template>

                            <template v-slot:[`item.idaux`]="{ item }">
                                <div v-if="item.qtde_subs > 0" style="display:flex;column:row;">
                                    <v-btn style="margin-left:10px" v-if="!item.fl_feito" small elevation="" color="info" @click="setarModalSubTarefas(item)">A Fazer</v-btn>
                                </div>
                                <div v-else style="display:flex;column:row;">
                                    <v-btn style="margin-left:10px" v-if="!item.fl_feito" small elevation="" color="info" @click="setarModalFeito(item)">A Fazer</v-btn>
                                    <v-btn style="margin-left:10px" v-else small elevation="" color="error" @click="setarModalDesfazer(item)">Desfazer</v-btn>
                                </div>
                            </template>
                            

                        </v-data-table>
                    
                    </v-row>

                  </v-container>
              </div>
             
          </div>
    </v-dialog>





      <v-dialog v-model="exibirModalCheckList" width="80vw" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">

              <div v-if="implantacaoAgendaSelecionada">
                 <v-container class="pa-8">
                    
                    
                    <v-row style="margin-top:5px;">
                        <v-col cols="12" md="10" >
                              <h1>Itens Pendentes - {{implantacaoAgendaSelecionada.nomesistema}}</h1>
                        </v-col>
                        <v-col cols="12" md="2" >
                                  <v-btn small elevation="" @click="rodarChecklist(implantacaoAgendaSelecionada)" color="info">Rodar Checklist</v-btn>
                              
                        </v-col>

                    </v-row>
                      
                    <v-row style="margin-top:5px;">
                      <v-col cols="12" md="6" >
                            <h3 style="color:blue">{{implantacaoAgendaSelecionada.nomecliente}}</h3>
                      </v-col>
                      <v-col cols="12" md="6" >
                      </v-col>
                    </v-row>
               
                    <v-row>
      
                        <v-data-table v-if="lista_checklist_pendente && lista_checklist_pendente.length > 0" 
                            :headers="headers_checklist"
                            :items="lista_checklist_pendente"
                            style="width:100%" >
                            

                        </v-data-table>
                    
                    </v-row>
                  </v-container>
              </div>
             
          </div>
    </v-dialog>




    <v-dialog v-model="exibirModalAgendaDesfazer" width="70vw" max-width="70vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div v-if="etapaMarcarDesfeitoSelecionado">
                 <v-container class="pa-8">
                    <h1>{{etapaMarcarDesfeitoSelecionado.etapa}}</h1>

                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="8">
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn elevation="" @click="() => salvarComoDesfeito(etapaMarcarDesfeitoSelecionado)" color="error" block>  Desfazer</v-btn> 
                        </v-col>
                    </v-row>
                    
                  </v-container>
              </div>
             
          </div>
    </v-dialog>


    <v-dialog v-model="exibirModalConfirmarDelete" width="70vw" max-width="70vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
            <!-- {{etapaMarcarFeitoSelecionado}} -->
              <div v-if="etapaMarcarFeitoSelecionado">
                 <v-container class="pa-8">

                    <h1>Confirma a remoção desta etapa do cronograma?</h1>
                    <br>
                    <p style="font-size:24px;padding-left:20px;">{{etapaMarcarFeitoSelecionado.texto}}</p>

                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="8">
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn elevation="" @click="() => removerEtapa(etapaMarcarFeitoSelecionado)" color="error" block>  Remover</v-btn> 
                        </v-col>
                    </v-row>
                    
                  </v-container>
              </div>
             
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalImpedimento" width="70vw" max-width="70vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
            <!-- {{etapaMarcarFeitoSelecionado}} -->
              <div v-if="etapaMarcarFeitoSelecionado">
                 <v-container class="pa-8">

                    <h1>Informe o motivo do impedimento</h1>
                    <br>
                    <p style="font-size:24px;padding-left:20px;">{{etapaMarcarFeitoSelecionado.texto}}</p>
                    


                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="12">
                            <v-textarea
                                    outlined
                                    placeholder="Motivo do Impedimento"
                                    label="Motivo do Impedimento"
                                    v-model="motivo_impedido"
                                  ></v-textarea> 
                        </v-col>
                    </v-row>
                    
                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="8">
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn elevation="" @click="() => confirmarImpedimentoTarefa()" color="error" block>  Adicionar Impedimento</v-btn> 
                        </v-col>
                    </v-row>
                    
                  </v-container>
              </div>
             
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalImpedimentoRemover" width="70vw" max-width="70vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
            <!-- {{etapaMarcarFeitoSelecionado}} -->
              <div v-if="etapaMarcarFeitoSelecionado">
                 <v-container class="pa-8">

                    <h1>Remover impedimento?</h1>
                    <br>
                    <p style="font-size:24px;padding-left:20px;">{{etapaMarcarFeitoSelecionado.texto}}</p>
                    <p style="font-size:24px;padding-left:20px;color:red;">{{etapaMarcarFeitoSelecionado.motivo_impedido}}</p>
                    
                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="8">
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn elevation="" @click="() => confirmarRemoverImpedimentoTarefa()" color="error" block>  Remover Impedimento</v-btn> 
                        </v-col>
                    </v-row>
                    
                  </v-container>
              </div>
             
          </div>
    </v-dialog>


    <v-dialog v-model="exibirModalAgendaFeito" width="70vw" max-width="70vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">

              <div v-if="etapaMarcarFeitoSelecionado">
                 <v-container class="pa-8">
                    <h1>{{etapaMarcarFeitoSelecionado.texto}}</h1>

                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="12">
                              <label for="observacao"><h3>Observação:</h3></label>
                              <textarea name="observacao" id="observacao" placeholder="Observação" v-model="observacaoetapa" style="border:1px solid black;width:100%;padding:5px;" cols="60" rows="5"></textarea>
                        </v-col>
                        <v-col cols="12" md="12">
                              <!-- <textarea name="" id="" v-model="etapaMarcarFeitoSelecionado.html_email" style="border:1px solid black;width:100%;" cols="60" rows="5"></textarea> -->
                              <label for="emailhtml"><h3>Texto para envio de Email ao Cliente (Opcional):</h3></label>
                              <vue-editor id="emailhtml" v-model="etapaMarcarFeitoSelecionado.html_email"></vue-editor>
                        </v-col>
                    </v-row>

                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="8">
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn elevation="" @click="() => salvarComoFeito(etapaMarcarFeitoSelecionado)" color="info" block>  Marcar Como Feito</v-btn> 
                        </v-col>
                    </v-row>
                    
                  </v-container>
              </div>
             
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalEtapaPadrao" width="50vw" max-width="50vw" >
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;height:300px;">

              <v-container class="" style="padding:20px;">
                 <v-row>
                    <v-col cols="12" md="4">
                        <v-btn small elevation="" color="info" @click="setarModalConfirmacaoCadEtapa(2)" >Cardapio Digital</v-btn>                    
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-btn small elevation="" color="info" @click="setarModalConfirmacaoCadEtapa(33)" >Check in Pay</v-btn>                    
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-btn small elevation="" color="info" @click="setarModalConfirmacaoCadEtapa(34)" >Check Out</v-btn>                    
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-btn small elevation="" color="info" @click="setarModalConfirmacaoCadEtapa(4)" >E-Spot</v-btn>                    
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-btn small elevation="" color="info" @click="setarModalConfirmacaoCadEtapa(6)" >FD Guest Concierge</v-btn>                    
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-btn small elevation="" color="info" @click="setarModalConfirmacaoCadEtapa(5)" >FD Guest Manutenção</v-btn>                    
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-btn small elevation="" color="info" @click="setarModalConfirmacaoCadEtapa(1)" >Ficha Digital</v-btn>                    
                    </v-col>
                   
                   
                   
                   
                 </v-row>
               </v-container>
             
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalConfirmarCadEtapaPadrao" width="60vw" max-width="60vw" height="" >
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;height:350px;">
              
              <v-container class="" style="padding:40px;">
                 <v-row>
                   <h1>Confirma o cadastro padrão de Etapas para:</h1>
                 </v-row>
                 <v-row>
                   <h1 style="color:blue;">{{tipoSistemaPadraoCadastro}}</h1>
                 </v-row>
                 <v-row>
                   <v-col cols="12" md="6">
                   </v-col>
                   <v-col cols="12" md="3">
                        <v-btn block elevation="" color="error" @click="cancelarCadastroEtapa()" >Cancelar</v-btn>                    
                   </v-col>
                   <v-col cols="12" md="3">
                        <v-btn block elevation="" color="info" @click="confirmarCadastroEtapa(tipoSistemaPadraoCadastro)" >Confirmar</v-btn>                    
                   </v-col>
                 </v-row>
               </v-container>
             
          </div>
    </v-dialog>


  </v-container>
</template>

<script>
import ImplantacaoServico from '@/services/implantacao.service.js'
import SistemaServico from '@/services/sistema.service.js'
import ClienteServico from '@/services/cliente.service.js'
import dayjs from 'dayjs'
 import { VueEditor } from 'vue2-editor'


export default {
  components: {
    VueEditor
  },
  data(){
    return {
      motivo_impedido:null,
      nomecliente:null,
      fl_ativo:true,
      motivoDesativacao:null,
      motivoAtivacao:null,
      headerImplantacoes:[
        { text: 'Cliente',     value: 'nomecliente', width:'25%' },
        { text: 'Sistema',     value: 'nomesistema', width:'20%' },
        { text: 'Status',     value: 'concluida', width:'10%' },
        { text: 'Próx. Agenda',     value: 'dia', width:'25%' },
        { text: 'Impedido',     value: 'impedido', width:'25%' },
        { text: 'Etapa Atual',     value: 'nomeetapa', width:'25%' },
        { text: 'Checklist',     value: 'percentual_checklist', width:'20%' },
      ],
      headersmovimentacao : [
        { text: 'Tipo',     value: 'tipo', width:'20%' },
        { text: 'Usuario',  value: 'nomeusuario', width:'10%' },
        { text: 'Dia',      value: 'dia', width:'20%' },
        { text: 'Evento',   value: 'observacao', width:'50%' },
      ],
      listamovimentacao:[],
      exibirModalMovimentos:false,
      exibirModalCancelamento:false,
      exibirModalAtivar:false,
      exibirModalSubTask:false,
      exibirModalAgenda:false,
      exibirModalAgendaFeito:false,
      exibirModalAgendaDesfazer:false,
      exibirModalConfirmarDelete:false,
      exibirModalImpedimento:false,
      exibirModalImpedimentoRemover:false,
      implantacoes: [],
      sistema:{id:0},
      sistemas: [],
      pesquisa: null,
      implantacoesdefault: [],
      cliente:{id:0,nome:'Todos'},
      clientes: [],
      reset: false,
      loading: false,
      status:{id:5,nome:'Pendentes'},
      listadestatus:[
        {id:0,nome:'Todos'},
        {id:1,nome:'Não Iniciado'},
        {id:2,nome:'Pendentes'},
        {id:3,nome:'Concluido'},
        {id:4,nome:'Atrasado'}
        // {id:5,nome:'Pendentes'}
      ],
      statuscliente:{id:1,nome:'Ativo'},
      listadestatuscliente:[
        {id:0,nome:'Inativado'},
        {id:1,nome:'Ativo'}
      ],
      statuschecklist: {id:0,nome:'Todos'},
      listastatuschecklist:[
        {id:0,nome:'Todos'},
        {id:1,nome:'Pendente'},
        {id:2,nome:'Concluido'}
      ],
      listadesistema:[],
      listatipoobs:[],
      tipoobs:{id:1,nome:'Simples'},
      listaobs:[],
      observacao:null,
      idimplantacaoselecionado:null,
      etapa_agendaimplantacao_sel:{
        id:null,
        textoetapa:null,
        dataetapa:null,
        horaetapa:null
      },
      subtask_selecionada:{
        id:null,
        textoetapa:null,
        dataetapa:null,
        horaetapa:null
      },
      headers_agenda:[
        { text: 'Id',     value: 'id', width:'10%' },
        // { text: 'Ordem',     value: 'ordem', width:'10%' },
        { text: 'Dt Est.',     value: 'data_entrega_formatado', width:'10%' },
        { text: 'Hr Est',     value: 'hora_entrega_formatado', width:'10%' },
        { text: 'SubTasks',     value: 'qtde_subs', width:'10%' },
        { text: 'Impedido',     value: 'impedido', width:'10%' },
        { text: 'Texto',     value: 'texto', width:'40%' },
        { text: 'Ação',     value: 'idaux', width:'10%' },
      ],
      headers_subtask:[
        { text: 'Id',     value: 'id', width:'10%' },
        { text: 'Ordem',     value: 'ordem', width:'10%' },
        { text: 'Texto',     value: 'texto', width:'60%' },
        { text: 'Ação',     value: 'idaux', width:'20%' },
      ],
      headers_checklist:[
        { text: 'Item Pendente', value: 'item', width:'100%' },
      ],
      items_agenda:null,
      items_subtask:null,
      implantacaoAgendaSelecionada:null,
      observacaoetapa:null,
      exibirModalEtapaPadrao:false,
      exibirModalConfirmarCadEtapaPadrao:false,
      tipoSistemaPadraoCadastro:null,
      etapaMarcarFeitoSelecionado:null,
      taskmae:null,
      etapaMarcarDesfeitoSelecionado:null,
      exibirModalCheckList:false,
      lista_checklist_pendente:[],
    }
  },
  methods: {
    removerImpedidoTarefa(dados){
      console.log('adicionarImpedidoTarefa ',dados)
      this.etapaMarcarFeitoSelecionado = dados
      this.exibirModalImpedimentoRemover = true
  
    },
    confirmarRemoverImpedimentoTarefa(){
      ImplantacaoServico.removerImpedidoTarefa(this.etapaMarcarFeitoSelecionado)
        .then(() => {
            this.exibirModalImpedimentoRemover = false
            this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente,idsistema:this.implantacaoAgendaSelecionada.idsistema})
        })
        .catch(error=>console.log(error))
    },
    adicionarImpedidoTarefa(dados){
      console.log('adicionarImpedidoTarefa ',dados)
      this.etapaMarcarFeitoSelecionado = dados
      this.exibirModalImpedimento = true
    },
    confirmarImpedimentoTarefa(){
      this.etapaMarcarFeitoSelecionado.motivo_impedido = this.motivo_impedido
      ImplantacaoServico.adicionarImpedidoTarefa(this.etapaMarcarFeitoSelecionado)
        .then(() => {
          this.exibirModalImpedimento = false
          this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente,idsistema:this.implantacaoAgendaSelecionada.idsistema})
        })
        .catch(error=>{
          console.log(error)
          this.exibirModalImpedimento = false  
        })
    },

    rodarChecklist(dados){
      console.log('rodarChecklist ',dados)
      dados.unico = true
        ImplantacaoServico.rodarChecklist(dados)
        .then(resp => {
          console.log(resp)
        })
        .catch(error=>console.log(error))
    },
    selecionarEtapaDoAgendamento(item_agendamento){
        console.log("item_agendamento ",item_agendamento)
        this.etapa_agendaimplantacao_sel = {
        id:item_agendamento.id ,
        texto:item_agendamento.texto,
        data_entrega:dayjs(item_agendamento.data_entrega).format('YYYY-MM-DD'),
        hora_entrega:item_agendamento.hora_entrega
      }
    },
    async removerEtapa(etapa){
      await ImplantacaoServico.removerAgendamento(etapa).then(resp => console.log(resp))
        .catch(error=>console.log(error))
        console.log(" JONON 2 ",etapa)
        
        await this.selecionarAgendamentosNew({idcliente:etapa.idcliente,idsistema:etapa.idsistema})
        this.exibirModalConfirmarDelete = false
    },
    async salvarAgendamento(){
        console.log(' salvarAgendamento this.implantacaoAgendaSelecionada ',JSON.stringify(this.implantacaoAgendaSelecionada,null,2))
        this.etapa_agendaimplantacao_sel.id_cliente = this.implantacaoAgendaSelecionada.idcliente
        this.etapa_agendaimplantacao_sel.id_sistema = this.implantacaoAgendaSelecionada.idsistema
        this.etapa_agendaimplantacao_sel.nome_cliente = this.implantacaoAgendaSelecionada.nomecliente
        console.log("JONON" ,JSON.stringify(this.etapa_agendaimplantacao_sel,null,2))
        await ImplantacaoServico.salvarAgendamento(this.etapa_agendaimplantacao_sel).then(resp => console.log(resp))
        .catch(error=>console.log(error))
        console.log(" JONON 2")
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente,idsistema:this.implantacaoAgendaSelecionada.idsistema})
        console.log(" JONON 3")
        this.limparSalvarAgendamento()
    },
    async limparSalvarAgendamento(){

        this.etapa_agendaimplantacao_sel = {}

    },
    async cadastroEtapasCardapioDigital(){

        let objeto = {
          idcliente:this.implantacaoAgendaSelecionada.idcliente,
          idsistema:this.implantacaoAgendaSelecionada.idsistema
        }
        await ImplantacaoServico.gerarCronograma(objeto)
        this.exibirModalConfirmarCadEtapaPadrao = false
        this.exibirModalEtapaPadrao = false
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente
                                          ,idsistema:this.implantacaoAgendaSelecionada.idsistema})

        await this.requisitarImplantacoes()                                          

    },
    async cadastroEtapasCheckIn(){

        let objeto = {
          idcliente:this.implantacaoAgendaSelecionada.idcliente,
          idsistema:this.implantacaoAgendaSelecionada.idsistema
        }
        await ImplantacaoServico.gerarCronograma(objeto)
        this.exibirModalConfirmarCadEtapaPadrao = false
        this.exibirModalEtapaPadrao = false
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente
                                          ,idsistema:this.implantacaoAgendaSelecionada.idsistema})

        await this.requisitarImplantacoes()                                          

    },
    async cadastroEtapasCheckOut(){
 
        let objeto = {
          idcliente:this.implantacaoAgendaSelecionada.idcliente,
          idsistema:this.implantacaoAgendaSelecionada.idsistema
        }
        await ImplantacaoServico.gerarCronograma(objeto)
        this.exibirModalConfirmarCadEtapaPadrao = false
        this.exibirModalEtapaPadrao = false
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente
                                          ,idsistema:this.implantacaoAgendaSelecionada.idsistema})

        await this.requisitarImplantacoes()                                          

    },
    async cadastroEtapasEspot(){

        // let diacorrente = dayjs().format('YYYY-MM-DD')
        // let diamovimentacao = diacorrente

        // //Etapa 1
        // this.etapa_agendaimplantacao_sel.textoetapa = 'Liberação da URL'
        // this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        // this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
        // await this.salvarAgendamento()

        // //Etapa 2
        // diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        // this.etapa_agendaimplantacao_sel.textoetapa = 'Testes de Integração'
        // this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        // this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
        //  await this.salvarAgendamento()

        // //Etapa 3
        // diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        // this.etapa_agendaimplantacao_sel.textoetapa = 'Meet de Configuração'
        // this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        // this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
        //  await this.salvarAgendamento()

        // //Etapa 4
        // diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        // this.etapa_agendaimplantacao_sel.textoetapa = 'Testes de Finalização de Fichas'
        // this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        // this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
        //  await this.salvarAgendamento()

        // //Etapa 5
        // diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        // this.etapa_agendaimplantacao_sel.textoetapa = 'Treinamento'
        // this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        // this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
        //  await this.salvarAgendamento()


        // //Etapa 6 Meet Ativação
        // diamovimentacao = dayjs(diamovimentacao).add(10,'day').format('YYYY-MM-DD')
        // this.etapa_agendaimplantacao_sel.textoetapa = 'Meet de Duvidas e Ativação'
        // this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        // this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
        //  await this.salvarAgendamento()


        let objeto = {
          idcliente:this.implantacaoAgendaSelecionada.idcliente,
          idsistema:this.implantacaoAgendaSelecionada.idsistema
        }
        await ImplantacaoServico.gerarCronograma(objeto)
        this.exibirModalConfirmarCadEtapaPadrao = false
        this.exibirModalEtapaPadrao = false
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente
                                          ,idsistema:this.implantacaoAgendaSelecionada.idsistema})
        await this.requisitarImplantacoes()                                          

    },
    async cadastroEtapasFDGuest(){

        let diacorrente = dayjs().format('YYYY-MM-DD')
        let diamovimentacao = diacorrente

        //Etapa 1
        this.etapa_agendaimplantacao_sel.textoetapa = 'Liberação da URL'
        this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
        await this.salvarAgendamento()

        //Etapa 2
        diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        this.etapa_agendaimplantacao_sel.textoetapa = 'Testes de Integração'
        this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
         await this.salvarAgendamento()

        //Etapa 3
        diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        this.etapa_agendaimplantacao_sel.textoetapa = 'Meet de Configuração'
        this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
         await this.salvarAgendamento()

        //Etapa 4
        diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        this.etapa_agendaimplantacao_sel.textoetapa = 'Testes de Finalização de Fichas'
        this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
         await this.salvarAgendamento()

        //Etapa 5
        diamovimentacao = dayjs(diamovimentacao).add(3,'day').format('YYYY-MM-DD')
        this.etapa_agendaimplantacao_sel.textoetapa = 'Treinamento'
        this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
         await this.salvarAgendamento()


        //Etapa 6 Meet Ativação
        diamovimentacao = dayjs(diamovimentacao).add(10,'day').format('YYYY-MM-DD')
        this.etapa_agendaimplantacao_sel.textoetapa = 'Meet de Duvidas e Ativação'
        this.etapa_agendaimplantacao_sel.dataetapa = diamovimentacao
        this.etapa_agendaimplantacao_sel.horaetapa = '14:00'
         await this.salvarAgendamento()

    },    
     async cadastroEtapasFichaDigital(){


        let objeto = {
          idcliente:this.implantacaoAgendaSelecionada.idcliente,
          idsistema:this.implantacaoAgendaSelecionada.idsistema
        }
        await ImplantacaoServico.gerarCronograma(objeto)
        this.exibirModalConfirmarCadEtapaPadrao = false
        this.exibirModalEtapaPadrao = false
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente
                                          ,idsistema:this.implantacaoAgendaSelecionada.idsistema})
        await this.requisitarImplantacoes()

    },
    async cadastroEtapasFDGuestConcierge(){

        let objeto = {
          idcliente:this.implantacaoAgendaSelecionada.idcliente,
          idsistema:this.implantacaoAgendaSelecionada.idsistema
        }
        await ImplantacaoServico.gerarCronograma(objeto)
        this.exibirModalConfirmarCadEtapaPadrao = false
        this.exibirModalEtapaPadrao = false
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente
                                          ,idsistema:this.implantacaoAgendaSelecionada.idsistema})
        await this.requisitarImplantacoes()                                          

    },    

    async cadastroEtapasFDGuestManutencao(){

        let objeto = {
          idcliente:this.implantacaoAgendaSelecionada.idcliente,
          idsistema:this.implantacaoAgendaSelecionada.idsistema
        }
        await ImplantacaoServico.gerarCronograma(objeto)
        this.exibirModalConfirmarCadEtapaPadrao = false
        this.exibirModalEtapaPadrao = false
        await this.selecionarAgendamentosNew({idcliente:this.implantacaoAgendaSelecionada.idcliente
                                          ,idsistema:this.implantacaoAgendaSelecionada.idsistema})
        await this.requisitarImplantacoes()                                          

    },    
   
    async confirmarCadastroEtapa(tiposistema){
      console.log(tiposistema)

        if(tiposistema == 2){
          await this.cadastroEtapasCardapioDigital()
        }

        if(tiposistema == 33){
          await this.cadastroEtapasCheckIn()
        }

        if(tiposistema == 34){
          await this.cadastroEtapasCheckOut()
        }

        if(tiposistema == 'Espot'){
          await this.cadastroEtapasEspot()
        }

        if(tiposistema ==5){
          await this.cadastroEtapasFDGuestManutencao()
        }

        if(tiposistema == 1){
          await this.cadastroEtapasFichaDigital()
        }

        /////////////////////////////////
        if(tiposistema == 6){
          await this.cadastroEtapasFDGuestConcierge()
        }
        
    },
    async registrarObservacao(){

        let objeto = {
            idimplantacao:this.idimplantacaoselecionado,
            idusuario:localStorage.getItem('idusuario'),
            observacao:this.observacao,
            idtipo:this.tipoobs.id
        }

        await ImplantacaoServico.registrarObservacao(objeto)
        this.setModalMovimentos(this.idimplantacaoselecionado)
    },
    async desativar(){



        //registrar a observação de cancelamento
        let objeto = {
            idimplantacao:this.idimplantacaoselecionado,
            idusuario:localStorage.getItem('idusuario'),
            observacao:this.motivoDesativacao,
            idtipo:6
        }

        await ImplantacaoServico.registrarObservacao(objeto)
        this.setModalCancelamento(null)


        await ImplantacaoServico.desativarImplantacao(objeto)
        this.requisitarImplantacoes()
    },
    criar(){
      window.open('/criar/implantacao','_BLANK')
    },
    async salvarComoFeito(etapa){

        console.log('etapa salvarComoFeito >> ',etapa)
        console.log('taskmae salvarComoFeito >> ',this.taskmae)
        etapa.id_usuario_feito = localStorage.getItem('idusuario')
        etapa.observacao = this.observacaoetapa
        // var p_etapa = etapa

        await ImplantacaoServico.salvarEtapaComoFeito(etapa).then(async resp =>{
            if(resp.status == 200){
                this.exibirModalAgendaFeito = false
                if(etapa.id_task > 0){
                    await this.selecionarAgendamentosNew({idcliente:this.taskmae.id_cliente,idsistema:this.taskmae.id_sistema})
                    await this.selecionarSubTasks(this.taskmae)
                    
                }else{
                    await this.selecionarAgendamentosNew({idcliente:this.taskmae.id_cliente,idsistema:this.taskmae.id_sistema})
                }
                this.observacaoetapa = null
                this.requisitarImplantacoes()
            }else{
                alert('deu ruim')
            }
        }).catch(()=>{
            alert('deu ruim mesmo')
        })
    },
    async salvarComoDesfeito(etapa){

        etapa.id_usuario_feito = localStorage.getItem('idusuario')
        etapa.observacao = this.observacaoetapa
        // var p_etapa = etapa

        console.log('salvarComoDesfeito 1 ')
        
        await ImplantacaoServico.salvarEtapaComoDesfeito(etapa).then(async resp =>{

            console.log('salvarComoDesfeito 2')

            if(resp.status == 200){
                console.log('salvarComoDesfeito 3')
                this.exibirModalAgendaDesfazer = false
                console.log('salvarComoDesfeito 4')
                await this.selecionarAgendamentosNew({idcliente:this.taskmae.id_cliente,idsistema:this.taskmae.id_sistema})
                await this.selecionarSubTasks(this.taskmae)
            }else{
              console.log('salvarComoDesfeito deu ruim')
                alert('deu ruim')
            }
        }).catch(()=>{
            console.log('salvarComoDesfeito erro 1 ')
            alert('deu ruim mesmo')
        })
    },
    async ativar(){

        //registrar a observação de cancelamento
        let objeto = {
            idimplantacao:this.idimplantacaoselecionado,
            idusuario:localStorage.getItem('idusuario'),
            observacao:this.motivoAtivacao,
            idtipo:7
        }

        await ImplantacaoServico.registrarObservacao(objeto)
        this.setModalAtivar(null)


        await ImplantacaoServico.ativarImplantacao(objeto)
        this.requisitarImplantacoes()
    },
    async setModalEtapasPadrao(){
        this.exibirModalEtapaPadrao = !this.exibirModalEtapaPadrao
    },
    async cancelarCadastroEtapa(){
      this.exibirModalConfirmarCadEtapaPadrao = false
      this.exibirModalEtapaPadrao = false
      this.tipoSistemaPadraoCadastro = null
    },
    async setarModalConfirmacaoCadEtapa(tiposistema){
      this.exibirModalConfirmarCadEtapaPadrao = !this.exibirModalConfirmarCadEtapaPadrao
      this.tipoSistemaPadraoCadastro = tiposistema
    },
    async setarModalSubTarefas(dados){
      this.exibirModalSubTask = !this.exibirModalSubTask
      this.etapaMarcarFeitoSelecionado = dados
      this.taskmae = dados
      this.selecionarSubTasks(dados) 
      
    },
    async setModalMovimentos(idimplantacao){
      this.exibirModalMovimentos = !this.exibirModalMovimentos
      this.idimplantacaoselecionado = idimplantacao
      this.tipoobs = {id:1,nome:'Simples'}
      this.observacao = null
      await this.listarObservacoes(idimplantacao)
      
    },
    async setModalCancelamento(idimplantacao){

      this.exibirModalCancelamento = !this.exibirModalCancelamento
      this.idimplantacaoselecionado = idimplantacao
      
    },
    async setModalAtivar(idimplantacao){

      this.exibirModalAtivar = !this.exibirModalAtivar
      this.idimplantacaoselecionado = idimplantacao
      
    },
    async setarModalFeito(dados){
      this.exibirModalAgendaFeito = !this.exibirModalAgendaFeito
      if(this.exibirModalAgendaFeito){
        this.etapaMarcarFeitoSelecionado = dados
      }else{
        this.etapaMarcarFeitoSelecionado = null
      }
      
    },
    async setarModalConfirmarDelete(dados){
        this.etapaMarcarFeitoSelecionado = dados
        this.exibirModalConfirmarDelete = !this.exibirModalConfirmarDelete 
    },
    async setarModalDesfazer(dados){
      this.exibirModalAgendaDesfazer = !this.exibirModalAgendaDesfazer
      if(this.exibirModalAgendaDesfazer){
        this.etapaMarcarDesfeitoSelecionado = dados
      }else{
        this.etapaMarcarDesfeitoSelecionado = null
      }
      
    },
    async setModalAgenda(dados){

      this.exibirModalAgenda = !this.exibirModalAgenda
      this.idimplantacaoselecionado = dados.idimplantacao
      this.implantacaoAgendaSelecionada = dados
      console.log('this.implantacaoAgendaSelecionada ',this.implantacaoAgendaSelecionada)
      if(this.exibirModalAgenda ){
        // this.selecionarAgendamentos({idcliente:dados.idcliente,idsistema:dados.idsistema})
        this.selecionarAgendamentosNew({idcliente:dados.idcliente,idsistema:dados.idsistema})
      }
      
    },
    async iniciarImplantacao(idimp){

      ImplantacaoServico.iniciarImplantacao({id:idimp}).then(res => {
        console.log(res.data)
        // this.redirecionar(`lista/implantacao/${idimp}`)
        window.open(`lista/implantacao/${idimp}`,'_BLANK')
      })
      
    },
    async selecionarAgendamentos(dados){

      ImplantacaoServico.selecionarAgendamentos({idcliente:dados.idcliente,idsistema:dados.idsistema}).then(res => {
        console.log('res.data', res.data)
        this.items_agenda = res.data
      })
      
    },
    async selecionarAgendamentosNew(dados){
      this.items_agenda = []
      ImplantacaoServico.selecionarAgendamentosNew({idcliente:dados.idcliente,idsistema:dados.idsistema}).then(res => {
        console.log('res.data', res.data)
        this.items_agenda = res.data
      })
      
    },
    async selecionarSubTasks(dados){
      this.items_subtask =[]
      console.log('selecionarSubTasks ',dados)
      let obj = {
        id_task:dados.id
      }
      ImplantacaoServico.selecionarSubTasks(obj).then(res => {
        console.log('res.data', res.data)
        this.items_subtask = res.data
      })
      
    },
    async responderChecklist(dados){

        this.lista_checklist_pendente = []
        // this.redirecionar(`lista/implantacao/${idimp}/checklist`)
        this.implantacaoAgendaSelecionada = dados
        if(dados?.checklist_falta){
        let checklist_pendente = dados.checklist_falta.split(';')
          for(let i = 0,len=checklist_pendente.length; i< len; i++){
              this.lista_checklist_pendente.push({item:checklist_pendente[i]})
          }
        }
        
        this.exibirModalCheckList = true
      
    },
    redirecionar(url){
      ImplantacaoServico.buscar().then(res => res.data)
      // this.$router.push(url)
      window.open(url,'_BLANK')
    },
    async requisitarImplantacoes(){

      

      let dados = {
          idsistema:this.sistema.id,
          idcliente:this.cliente.id,
          status:this.status.id,
          statuscliente:this.statuscliente.id,
          statuschecklist:this.statuschecklist.id,
          fl_ativo:this.fl_ativo,
          nomecliente:this.nomecliente
      }

      if (dados.idsistema == 0){ dados.idsistema = null }
      if (dados.idcliente == 0){ dados.idcliente = null }
      if (dados.status == 0){ dados.status = null }
      if (dados.statuschecklist == 0){ dados.statuschecklist = null }

    

      const implantacoes = await ImplantacaoServico.buscar(dados).then(res => res.data)
      this.implantacoes = implantacoes
      this.implantacoesdefault = implantacoes
    },
    async requisitarSistemas(){

      // this.listadesistema = await SistemaServico.buscar({semimagem:1}).then(res => res.data)

      let sistemas = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
      this.listadesistema = [{id:0,nome:'Todos'}]
      this.listadesistema = this.listadesistema.concat(sistemas)


    },
    async requisitarTipoObservacoes(){
      
      this.listatipoobs = await ImplantacaoServico.requisitarTipoObservacoes().then(res => res.data)
    },
    async listarObservacoes(idimplantacao){

      this.listamovimentacao = await ImplantacaoServico.listarObservacoes({idimplantacao:idimplantacao}).then(res => res.data)
    
      await this.requisitarImplantacoes()
 
    },
    async requisitarClientes(){
      
      let lclientes = await ClienteServico.buscar().then(res => res.data)
      this.clientes = [{id:0,nome:'Todos'}]
      this.clientes = this.clientes.concat(lclientes)

    },
    // filtrar(){
    //   const query = this.pesquisa.toLowerCase();
    //   const novoestado = this.implantacoes.filter((implantacao) => {
    //     return Object.values(implantacao).some((attr) =>{
    //       return String(attr).toLowerCase().includes(query)
    //       }
    //     );
    //   });
    //   this.implantacoes = novoestado
    // },
    limparFiltros(){

      this.sistema={}
      this.cliente={}
      this.status={}
      this.statuschecklist={}
    },

  },

  watch: {

  },
  async mounted(){

    await this.requisitarSistemas()
    await this.requisitarClientes()
    await this.requisitarImplantacoes()
    await this.requisitarTipoObservacoes()

    

  }
}
</script>

<style lang="scss" scoped>
.grow{
  flex: 1;
}
.header{
  display: flex;


}
.lista-header{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: .5em;
  height: fit-content;
  min-height: 3em;
  margin-bottom: 1em;
  border-radius: 1em;
  background: #ffffff;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
  >.btn{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
    z-index: 111;
    outline: none;
    
    &.rounded{
      border-radius: 50% !important;
      padding: 1em;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90vw;   
    .search-wrapper{
      margin-right: 12% !important;
    }
    .reset-wrapper{
      right: -4% !important;
      padding: .2em .5em !important;
    }
  }

  .search-wrapper{
    display: flex;
    align-items: center;
    box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    padding: .5em 1em;
    border-radius: 1em;

    >input[type="search"]{
      outline: none;
      height: 100%;
    }
    >.btn{
      line-height: 1.5;
      color: #1377f2;
      font-weight: bold;
      letter-spacing: 0.02em;
      border: 2px solid #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
      width: fit-content;

      &.rounded{
        border-radius: 50%;
        padding: 1em;
      }
    }
  }
  .status-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 1em;
    min-width: 6em;

    label{
      font-weight: bold;
      color: #aaa;
      letter-spacing: 0.02em;
    }

    >span{
      display: flex;
      align-items: center;

      >input[type="checkbox"]{
        margin-right: .2em;
      }

      >p{
        font-size: 12px;
        text-transform: capitalize;
      }
    }
    @media screen and (max-width: 768px){
      display: none;
    }
  }
  .reset-wrapper{
    background: #1377f2;
    border-radius: .5em;
    position: absolute;
    right: -5%;
    padding: .2em 1em;
  }
}

.lista{
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;    
  }
  .field{
    width: 10%;
  }
  .field:nth-child(2){
    width: 25%;
    align-items: flex-end; 
  }
  .field:nth-child(3){
    width: 25%;
    align-items: flex-end;
  }
  .field:nth-child(4){
    width: 25%;
    align-items: flex-end;
  }
  .field:nth-child(5){
    width: 15%;
    align-items: flex-end;
  }
  @media screen and (max-width: 768px) {
    .field:nth-child(2){
      width: 30%;
    }
    .field:nth-child(3){
      width: 30%;
    }
    .field:nth-child(4){
      display: none;
    }
    .field:nth-child(5){
      width: 20%;
    }
  }
  @media screen and (max-width: 414px) {
    .field:nth-child(2){
     display: none;
    }
    .field:nth-child(3){
      width: 50%;
    }
    .field:nth-child(4){
      display: none;
    }
    .field:nth-child(5){
      width: 30%;
    }
  }
  overflow-y: scroll;
  max-height: 70vh;
  @media screen and (max-width: 768px) {
    max-height: 75vh;    
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.item-lista{
  padding: .5em 2em;
  height: 4em;
  margin-bottom: 1em;
  border-radius: 5px 5px 30px 30px;
  background: #ffffff;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: .5em 2em;
  }

}


.field{
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p:nth-child(1){
      font-size: 12px;
      font-weight: bold;
      color: rgba(0,0,0,0.5);
    }
    p:nth-child(2){
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      font-size: 14px; 
    }
    .btn-acao{
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 10px 10px 20px 10px;
    }
  }
  &:nth-child(2){
    z-index: 1;
  }
  &.white{
    >span{
      >label{
        color: #f9f9fe;
      }
      >input{
        color: #f9f9fe;
      }
    }
  }
}
//css indesejado
.v-application .rounded {
  border-radius: unset !important;
}

.v-application ul, .v-application ol {
    padding-left: unset !important;
}

</style>
