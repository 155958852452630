import axios from 'axios'

export default () => {

  return axios.create({
      // baseURL: (process.env.NODE_ENV === 'development') ? 'http://localhost:3343/' : 'https://apies.economysoftware.com.br/',
      baseURL: (process.env.NODE_ENV === 'development') ? 'http://localhost:3343/' : 'https://apies.economysoftware.com.br/',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      }
  })

  // return axios.create({
  //     baseURL: 'https://apies.economysoftware.com.br/',
  //     headers: {
  //       'Content-Type': 'application/json; charset=utf-8',
  //       'Access-Control-Allow-Origin': '*'
  //     }
  // })

}

// export default () => {

//   return axios.create({
//     baseURL: 'https://apies.economysoftware.com.br/', 
//     headers: {
//       'Content-Type': 'application/json; charset=utf-8',
//       'Access-Control-Allow-Origin': '*'
//     }
//   })
  
// }
