<template>
  <v-app id="app">
        <v-toolbar elevation="0" color="#1377F2" v-if="verificarExibirTooBar()">
          <v-app-bar-nav-icon @click="drawer=!drawer" color="#f9f9fe">
          </v-app-bar-nav-icon>

          <v-toolbar-title @click="$router.push('/')"><span style="color: #f9f9fe;">ES Implantações</span></v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon color="#f9f9fe">mdi-magnify</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon color="#f9f9fe" @click="voltar">mdi-undo</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon color="#f9f9fe" @click="logoutwrapper=!logoutwrapper">mdi-dots-vertical</v-icon>
          </v-btn>
          <div class="logout-wrapper" v-if="logoutwrapper">
            <v-btn icon @click="requisitarLogout">
              <v-icon color="#1377f2">mdi-logout</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-navigation-drawer 
          v-model="drawer"
          temporary
          floating
          app
        >
          <div class="header-drawer">
            <span>logo.</span>
            <h3 >ES TASK CHECK</h3>
          </div>

          <v-divider></v-divider>

          <v-list v-if="loading" 
            nav
            dense
          >


            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     INICIO DO MENU SUPORTE        ------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

              <v-list-item link @click="menuSuporte = !menuSuporte" >
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-face-agent</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Sup. & Implantção
                  </v-list-item-title>
                </v-list-item>
              <v-divider v-if="listagemMenu.cadastro"></v-divider>

              <v-list v-if="menuSuporte" nav dense >


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/dashboard')" v-if="listagemMenu.dashboard">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-monitor-dashboard</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title style="padding-left:-20px;">
                          DashBoard
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.dashboard" ></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Home')" v-if="listagemMenu.implantacao">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-list-status</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Implantações
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.implantacao" ></v-divider>


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/LogEmails')" v-if="listagemMenu.quadroimplantacao">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-card-account-mail-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Log Emails
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.quadroimplantacao"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/PreRequisitos')" v-if="listagemMenu.quadroimplantacao">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-invoice-list-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Pré Requisitos Sistemas
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.quadroimplantacao"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/QuadroImplantacao')" v-if="listagemMenu.quadroimplantacao">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-chart-line</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Quadro de Implantações
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.quadroimplantacao"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/ServidoresSuporte')" v-if="listagemMenu.quadroimplantacao">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-server-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Servidores
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.quadroimplantacao"></v-divider>


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Treinamentos')" v-if="listagemMenu.quadroimplantacao">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-school-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Treinamentos
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.quadroimplantacao"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Aprendizado')" v-if="listagemMenu.quadroimplantacao">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-notebook</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Aprendizado
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.quadroimplantacao"></v-divider>




              </v-list>

            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------      FIM DO MENU SUPORTE       ---------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  




            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------     INICIO DO MENU ATIVIDADES        ----------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

              <v-list-item link @click="menuAtividades = !menuAtividades" >
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-calendar-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Atividades
                  </v-list-item-title>
                </v-list-item>
              <v-divider v-if="listagemMenu.cadastro"></v-divider>

              <v-list v-if="menuAtividades" nav dense >


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Atividades')" v-if="listagemMenu.atividade" >
                      <v-list-item-icon style="margin-right:5px!important;">
                        <v-icon color="#1377F2">mdi-target-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Atividades
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.atividade"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Solicitacoes')" v-if="listagemMenu.solicitacao" >
                      <v-list-item-icon style="margin-right:5px!important;">
                        <v-icon color="#1377F2">mdi-ticket</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Solicitações
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.solicitacao"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Projetos')" v-if="listagemMenu.projeto">
                      <v-list-item-icon style="margin-right:5px!important;">
                        <v-icon color="#1377F2">mdi-application-settings-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Projetos
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.projeto" ></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/PrioridadesPlanejadas')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-format-list-bulleted-triangle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Prioridades
                            </v-list-item-title>
                    </v-list-item>



              </v-list>

            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!-------------------------------      FIM DO MENU ATIVIDADES       --------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  




            
            

            


            
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     INICIO DO MENU Contatos      ------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

              <v-list-item link @click="menuContatos = !menuContatos" v-if="listagemMenu.contato">
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Contatos
                  </v-list-item-title>
                </v-list-item>
              <v-divider v-if="listagemMenu.cadastro"></v-divider>

              <v-list v-if="menuContatos" nav dense >


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Contatos')" v-if="listagemMenu.contato">
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-phone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Contatos
                            </v-list-item-title>
                    </v-list-item>

                    <!-- <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/cliente')" v-if="listagemMenu.contato">
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Tipos de Contato
                            </v-list-item-title>
                    </v-list-item>
           -->
              </v-list>
            


            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     INICIO DO MENU CADASTROS      ------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

              <v-list-item link @click="menusCadastros = !menusCadastros" v-if="listagemMenu.cadastro">
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Cadastros
                  </v-list-item-title>
                </v-list-item>
              <v-divider v-if="listagemMenu.cadastro"></v-divider>


              <v-list v-if="menusCadastros" nav dense >


                    


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/faq')" v-if="listagemMenu.cadastrofaq">
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-frequently-asked-questions</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Faq
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrofaq"></v-divider>


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/sistema')" v-if="listagemMenu.cadastrosistema" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-application-braces-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Sistemas
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrosistema" ></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/cadastrar/contrato')" v-if="listagemMenu.cadastrocontrato" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-file-table-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Contratos
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrocontrato"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/cadastrar/stackholdersTipo')" v-if="listagemMenu.cadastrotipostakeholder" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-head-plus-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Stakeholders tipos
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrotipostakeholder"></v-divider>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/stakeholder')" v-if="listagemMenu.cadastrostakeholder" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-head-cog-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Stakeholder
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrostakeholder"></v-divider>


                    


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/tutoriais')" v-if="listagemMenu.cadastrotutorial" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-cast-education</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Tutoriais
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrotutorial"></v-divider>


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/usuario')" v-if="listagemMenu.cadastrousuario" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-account-circle-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Usuários
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrousuario" ></v-divider>


                    

           


                    
              
              
              </v-list>

            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     FIM DO MENU CADASTROS      ---------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  




            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     INICIO DO MENU UTILIDADES      ------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

              <v-list-item link @click="menuUtilidades = !menuUtilidades" v-if="listagemMenu.contato">
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-tools</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Utilidades
                  </v-list-item-title>
                </v-list-item>
              <v-divider v-if="listagemMenu.cadastro"></v-divider>

              <v-list v-if="menuUtilidades" nav dense >


                    

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Jenkins')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-wrench-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Jenkins
                            </v-list-item-title>
                    </v-list-item>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Layouts')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-page-layout-body</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Layouts
                            </v-list-item-title>
                    </v-list-item>

                    



                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Robos')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-robot-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Robos
                            </v-list-item-title>
                    </v-list-item>
                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Drives')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-robot-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Drives
                            </v-list-item-title>
                    </v-list-item>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/dadossistemas')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-server-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Servidores
                            </v-list-item-title>
                    </v-list-item>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/StatusSistemas')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-view-module</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Status Sistemas Modulos
                            </v-list-item-title>
                    </v-list-item>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Termos')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-file-pdf-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Termos Padrão
                            </v-list-item-title>
                    </v-list-item>

                    

              </v-list>

              

            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     FIM DO MENU UTILIDADES      ---------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  


            <!--------------------------------------------------------------------------------------------------->
            <!--------------------------------------------------------------------------------------------------->
            <!-------------------------------     INICIO DO MENU RELATORIOS      -------------------------------->
            <!--------------------------------------------------------------------------------------------------->
            <!--------------------------------------------------------------------------------------------------->
            
              <v-list-item link @click="menuRelatorios = !menuRelatorios" v-if="listagemMenu.relatorio">
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-chart-areaspline-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Relatórios
                  </v-list-item-title>
                </v-list-item>
              <v-divider  v-if="listagemMenu.relatorio"></v-divider>

              <v-list v-if="menuRelatorios" nav dense >
                <v-list-item style="margin-left:30px;" link @click="$router.push('/relatorios/curtidasfaqvideos')">
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-file-chart-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Curtidas FAQs
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
              </v-list>


            <!--------------------------------------------------------------------------------------------------->
            <!--------------------------------------------------------------------------------------------------->
            <!--------------------------------     FIM DO MENU RELATORIOS      ---------------------------------->
            <!--------------------------------------------------------------------------------------------------->
            <!--------------------------------------------------------------------------------------------------->



            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     INICIO DO MENU FLUXOS      ---------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

            <!-- <v-list-item link @click="menusFluxos = !menusFluxos" v-if="listagemMenu.fluxo" >
                <v-list-item-icon style="margin-right:5px!important;">
                  <v-icon color="#1377F2">mdi-clipboard-flow</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Fluxos
                </v-list-item-title>
              </v-list-item>
            <v-divider v-if="listagemMenu.fluxo" ></v-divider>

            <v-list v-if="menusFluxos" nav dense >

                  <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/gadget')">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-widgets-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Gadgets
                        </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>


                  <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/etapa')">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-card-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Etapas
                        </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>


                  <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/fluxo')">
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-sitemap-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Fluxos
                        </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>


            </v-list> -->


            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     FIM DO MENU FLUXOS      ------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  


            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------     INICIO DO MENU CLIENTES          ----------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

              <v-list-item link @click="menuClientes = !menuClientes" >
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-account-group-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Clientes
                  </v-list-item-title>
                </v-list-item>
              <v-divider v-if="listagemMenu.cadastro"></v-divider>

              <v-list v-if="menuClientes" nav dense >

                    <v-list-item style="margin-left:30px;"  link @click="$router.push('/lista/cliente')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-briefcase-account-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Cadastro de Clientes
                            </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="listagemMenu.cadastrocliente"></v-divider>


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/modulos')" >
                      <v-list-item-icon style="margin-right:5px!important;">
                        <v-icon color="#1377F2">mdi-view-module</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Módulos por Cliente
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider ></v-divider>


                      <v-list-item style="margin-left:30px;" link @click="$router.push('/lista/utilizacao')" v-if="listagemMenu.utilizacao" >
                        <v-list-item-icon style="margin-right:5px!important;">
                          <v-icon color="#1377F2">mdi-chart-areaspline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Utilização Clientes
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider v-if="listagemMenu.utilizacao"></v-divider>



              </v-list>

            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!-------------------------------      FIM DO MENU CLIENTES         --------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  




            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------     INICIO DO MENU PARCEIROS      ------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  

              <v-list-item link @click="menuParceiros = !menuParceiros" >
                  <v-list-item-icon style="margin-right:5px!important;">
                    <v-icon color="#1377F2">mdi-handshake</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Parceiros
                  </v-list-item-title>
                </v-list-item>
              <v-divider v-if="listagemMenu.cadastro"></v-divider>

              <v-list v-if="menuParceiros" nav dense >


                    <v-list-item style="margin-left:30px;" link @click="$router.push('/ContatoParceiros')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-phone-check-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Contato Parceiros
                            </v-list-item-title>
                    </v-list-item>

                    <v-list-item style="margin-left:30px;" link @click="$router.push('/CredenciaisParceiros')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-id-card</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Credenciais Parceiros
                            </v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item style="margin-left:30px;" link @click="$router.push('/Parceiros')" >
                            <v-list-item-icon style="margin-right:5px!important;">
                              <v-icon color="#1377F2">mdi-handshake-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Parceiros
                            </v-list-item-title>
                    </v-list-item>



              </v-list>

            <!--------------------------------------------------------------------------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------      FIM DO MENU PARCEIROS     ---------------------------------->  
            <!--------------------------------------------------------------------------------------------------->  
            <!---------------------------------------------------------------------------------------------------> 

            

            <v-list-item link @click="$router.push('/apis/status')" v-if="listagemMenu.statusapi">
                    <v-list-item-icon style="margin-right:5px!important;">
                      <v-icon color="#1377F2">mdi-code-braces</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Status das APIs
                    </v-list-item-title>
            </v-list-item>
            <v-divider v-if="listagemMenu.statusapi" ></v-divider>
            
            <v-list-item link @click="$router.push('/sql/monitor')" v-if="listagemMenu.monitoramentosql" >
                    <v-list-item-icon style="margin-right:5px!important;">
                      <v-icon color="#1377F2">mdi-database-search</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Monitoramento de SQL's
                    </v-list-item-title>
            </v-list-item>

          </v-list>
          <NavSkeleton v-if="!loading" />
        </v-navigation-drawer>
        <router-view/>
  </v-app>
</template>

<script>
import AutenticacaoServico from '@/services/autenticacao.service.js'
import NavSkeleton from '@/components/Loading/NavSkeleton.vue'
// import {notificarChrome} from '@/services/notificacao.service.js'

  export default {
  components: { NavSkeleton },
    data () {
      return {
        drawer: true,
        mini: true,
        logoutwrapper: false,
        token: null,
        loading: false,
        menusFluxos:false,
        menusCadastros:false,
        menuContatos:false,
        menuSuporte:false,
        menuParceiros:false,
        menuAtividades:false,
        menuClientes:false,
        menuUtilidades:false,
        menuRelatorios: false,
        tipousuario:null,
        listagemMenu:{
          dashboard:false,
          implantacao:false,
          quadroimplantacao:false,
          projeto:false,
          atividade:false,
          contato:false,
          solicitacao:false,
          cadastro:false,
          relatorio:false,
          fluxo:false,
          modulocliente:false,
          utilizacao:false,
          statusapi:false,
          monitoramentosql:false,
          cadastrocliente:false,
          cadastrofaq:false,
          cadastrosistema:false,
          cadastrocontrato:false,
          cadastrotipostakeholder:false,
          cadastrostakeholder:false,
          cadastrotutorial:false,
          cadastrousuario:false,          
        }
      }
    },
    methods: {
      voltar(){
        //como fazer para o usuario vltar na historia do navegador apenas se não estiver na home page
        this.$router.go(-1)
      },
      requisitarLogout(){
        AutenticacaoServico.logout()
      },

      verificarExibirTooBar() {
        if(this.token && this.token !== 'token') {
          return true;
        }
        return false;
      }
    },
    created(){
      this.token = localStorage.getItem('tktaskcheck')
    },
    beforeUpdate(){
      this.token = localStorage.getItem('tktaskcheck')
    },
    mounted(){

      this.tipousuario = localStorage.getItem('tipousuario')

        
      console.log(' this.tipousuario ', this.tipousuario)
      if(this.tipousuario == 1 || this.tipousuario == "null"){

          this.listagemMenu.dashboard = true
          this.listagemMenu.implantacao = true
          this.listagemMenu.quadroimplantacao = true
          this.listagemMenu.projeto = true
          this.listagemMenu.atividade = true
          this.listagemMenu.contato = true
          this.listagemMenu.solicitacao = true
          this.listagemMenu.cadastro = true
          this.listagemMenu.cadastrocliente = true
          this.listagemMenu.cadastrofaq = true
          this.listagemMenu.cadastrosistema = true
          this.listagemMenu.cadastrocontrato = true
          this.listagemMenu.cadastrotipostakeholder = true
          this.listagemMenu.cadastrostakeholder = true
          this.listagemMenu.cadastrotutorial = true
          this.listagemMenu.cadastrousuario = true
          this.listagemMenu.relatorio = true
          this.listagemMenu.fluxo = true
          this.listagemMenu.modulocliente = true
          this.listagemMenu.utilizacao = true
          this.listagemMenu.statusapi = true
          this.listagemMenu.monitoramentosql = true
          

      }

      if(this.tipousuario == 3){

          this.listagemMenu.dashboard = false
          this.listagemMenu.implantacao = false
          this.listagemMenu.quadroimplantacao = false
          this.listagemMenu.projeto = false
          this.listagemMenu.atividade = false
          this.listagemMenu.contato = false
          this.listagemMenu.solicitacao = false
          this.listagemMenu.cadastro = false
          this.listagemMenu.cadastrocliente = false
          this.listagemMenu.cadastrofaq = false
          this.listagemMenu.cadastrosistema = false
          this.listagemMenu.cadastrocontrato = false
          this.listagemMenu.cadastrotipostakeholder = false
          this.listagemMenu.cadastrostakeholder = false
          this.listagemMenu.cadastrotutorial = false
          this.listagemMenu.cadastrousuario = false
          this.listagemMenu.relatorio = false
          this.listagemMenu.fluxo = false
          this.listagemMenu.modulocliente = false
          this.listagemMenu.utilizacao = false
          this.listagemMenu.statusapi = false
          this.listagemMenu.monitoramentosql = false


          this.listagemMenu.dashboard = true
          this.listagemMenu.implantacao = true
          this.listagemMenu.quadroimplantacao = false
          this.listagemMenu.atividade = true
          this.listagemMenu.solicitacao = true
          this.listagemMenu.cadastro = true
          this.listagemMenu.cadastrocliente = true
          this.listagemMenu.cadastrostakeholder = true
          this.listagemMenu.utilizacao = true


      }

      console.log('his.$router')
      console.log(this.$router)
      
      // 

        setTimeout(()=>{
          // alert(this.$router.currentRoute.path.includes('portal'))

            if(!this.$router.currentRoute.path.includes('portal')){

                if(this.$router.currentRoute.fullPath.includes('realizar/etapa')){
                  if(!this.$route.params.token){
                    this.$router.push('/login')
                  }
                }else{
                    if(!this.token){
                        if(!this.$router.currentRoute.fullPath.includes('Apresentacao')){
                            this.$router.push('/login')
                        }
                    }
                }
                this.loading= true

           }
        },1000)

      //

      // this.$socket.disconnect();
      // this.$socket.io.opts.query.tipo = 'centralcontrole'
      // this.$socket.io.opts.query.emailusuario = localStorage.getItem('email')
      // this.$socket.connect();
      // this.sockets.subscribe("centralfront_notificarchrome", (dados) => {
      //   console.log(dados)
      //     notificarChrome({titulo:'meu titulo',texto:'sabado 2'})
      // });
      

    },
  }
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
//imagens responsivas
img{
  width: 100%;
  height: auto;
}

li{
  list-style: none;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: rgb(240, 235, 248);
  height: fit-content;
  min-height: 100vh;
}
.logout-wrapper{
  background-color: #f9f9f2;
  border-radius: 15px;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.header-drawer{
  background: #1377F2;
  height: 64px;

  @media screen and (max-width:768px) {
    height:56px;    
  }

  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.5em 1em;
  color: white;
}
.v-list-item__title{
  display: flex;
  justify-content: flex-start;
  padding-left: 1em;
}
.v-navigation-drawer--temporary{
  z-index: 333 !important;
}
.v-data-table{
  border-radius: 1em !important;
}
//css indesejado
.v-application--wrap {
    flex: 1 !important;   
    -webkit-backface-visibility: unset !important;
    backface-visibility: unset !important;
    display: unset !important;
    flex-direction: unset !important;
    min-height: unset !important;
    max-width: unset !important;
    position: unset !important;
}
.v-application p{
  margin-bottom: unset !important;
}
.v-navigation-drawer__content{
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
